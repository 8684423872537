$(function(){
   $(document).on("click",function(e){
      var ele = $(e.target);
      
      if(ele.closest("#menu-toggle").length){
         e.preventDefault();
         
         $(".sidebar").addClass("opened");
         $("body").addClass("nav-open");
      }
      if(ele.closest("#menu-close").length){
         e.preventDefault();
         
         $(".sidebar").removeClass("opened");
         $("body").removeClass("nav-open");
      }
   });
   $(document).mouseup(function(e){
      var container = $("#main-navigation.opened");
      // if the target of the click isn't the container nor a descendant of the container
      if (!container.is(e.target) && container.has(e.target).length === 0) 
      {
         container.removeClass("opened");
         $("body").removeClass("nav-open")
      }
   });
});
var editorContent;
var jsonItem = {
   "template_id": "INV001",
   "page_size": "A4",
   "margin_top": "0.1cm",
   "margin_right": "0.1cm",
   "margin_bottom": "0.1cm",
   "margin_left": "0.1cm",
   "landscape": false,
   "data": ''
};
var jsonItemData = {};
var editorContentToReplace = '';
var jsonFromPreview = false;
$(function(){
   $(document).on("click",function(e){
      var ele = $(e.target);
      if(ele.closest('[data-toggle="modal"]').length){
         $(".editorContent-full").html('');
         $(ele.attr("data-target")).show().animate({"opacity":1},350).addClass('fade-in');
      }
      if(ele.closest('.close-modal').length) {
         ele.closest('.modal').removeClass('fade-in').animate({"opacity":0},350);
         setTimeout(function(){
            ele.closest('.modal').hide();
            if(ele.hasClass("modal-submit")) {
               ele.closest('form').submit();
            }
         },700);
      }
      if(ele.closest("#addNewRow").length){
         var tRow = ele.closest('tr').prev('tr').clone();
         tRow.find("input,select,textarea").val('');
         tRow.find('td:last-child').html('<button class="btn btn-regular removeRow"><i class="fas fa-times"></i></button>');
         tRow.insertBefore(ele.closest('tr'));
      }
      if(ele.closest('.removeRow').length){
         ele.closest('tr').remove();
      }
      if(ele.closest('#generateSampleBtn').length){
         updateSampleTable();
         generatePreviewInModal();
         initAccordion();
      }
      if(ele.closest("#generatePreview").length){    
         generatePreviewInModal();
         ele.closest(".accordion-item").find(".accordion-body").slideUp();
         ele.closest(".accordion-item").find(".accordion-header").removeClass('opened');
         ele.closest(".accordion-item").next(".accordion-item").find(".accordion-body").slideDown();
         ele.closest(".accordion-item").next(".accordion-item").find(".accordion-header").addClass('opened');
      }
      if(ele.closest("#exAPIRequest").length){
         if(!jsonFromPreview){
            jsonItemData = {};
            if ($('#is_complex_html').val() == 'complex') {

               // Find the content inside the first div
               const firstDivElement = $('#complex_template_content').val();

               // Function to extract content between {{ and }} and remove spaces
               function extractDoubleBracesContent(input) {
                   const results = {};
                   let start = input.indexOf('{{');
                 
                   while (start !== -1) {
                     const end = input.indexOf('}}', start + 2);
                 
                     if (end !== -1) {
                       const content = input.substring(start + 2, end).trim();
                       results[content] = ''; // You might want to assign a meaningful value here
                       start = input.indexOf('{{', end + 2);
                     } else {
                       break;
                     }
                   }
                 
                   return results;
               }

               // Example: Extract and display double and single curly braces content
               // const doubleBracesContent = extractDoubleBracesContent(firstDivElement);
                 var editorContentJson = $('#complex_template_content').val();
                 if(editorContentJson.indexOf("body") !== -1){
                     editorContentJson = editorContentJson.split("<body>")[1];
                     editorContentJson = editorContentJson.split("</body")[0];
                     editorContentJson = editorContentJson.replaceAll(/<script([\s\S]*)script>/gm, "");;
                     editorContentJson = editorContentJson.split("{{");
                     for(var i=0;i<editorContentJson.length;i++){
                        if($.inArray(editorContentJson[i].indexOf("<script")) == -1){
                           if($.inArray("}",editorContentJson[i]) !== -1){
                              jsonItemData[editorContentJson[i].split('}}')[0].replaceAll(/&nbsp;/g,'').trim().replaceAll(" ","_").toLowerCase()] =  "";
                           }
                        }
                     } 
                  }
                  else {
                     jsonItemData = extractDoubleBracesContent(editorContentJson)
                  }

               // console.log("Double Curly Braces Data:", JSON.stringify(jsonItemData, null, 4));
               jsonItem["template_id"] = $('input[name=template_number]').val();
               jsonItem["data"] = jsonItemData;
            }else{

               var editorContentJson = tinymce.get("tinymceEditor").getContent();
               var editorContentJsonMulti = editorContentJson.split("table");
               
               if(editorContentJson.indexOf("%}") != -1){
                  for(var k = 0; k <editorContentJsonMulti.length; k++) {
                     
                     var editorContentJsonMultiDouble = editorContentJsonMulti[k].split("{{");  
                     
                     
                     if(editorContentJsonMulti[k].indexOf("%}") != -1){
                        for (var db = 0; db <editorContentJsonMultiDouble.length; db++){
                           if(editorContentJsonMultiDouble[db].indexOf("}}") != -1){
                              
                              jsonItemData[editorContentJsonMultiDouble[db].split("}}")[0].replaceAll(/&nbsp;/g,'').trim().replace(".","_")] = '';
                           }
                        }                  
                        
                     } else {
                        var emptyJSONItems = {}
                        for (var db = 0; db <editorContentJsonMultiDouble.length; db++){
                           if(editorContentJsonMultiDouble[db].indexOf("}}") != -1){
                              emptyJSONItems[editorContentJsonMultiDouble[db].split("}}")[0].replaceAll(/&nbsp;/g,'').trim().replace(".","_")] = '';
                           }
                        }
                        jsonItemData["items"] = emptyJSONItems;
                     }
                  }
               } else {
                  
                  var editorContentJson = tinymce.get("tinymceEditor").getContent();
                  editorContentJson = editorContentJson.split("{{");
                  for(var i=0;i<editorContentJson.length;i++){
                     if($.inArray("}",editorContentJson[i]) !== -1){
                        jsonItemData[editorContentJson[i].split('}}')[0].replaceAll(/&nbsp;/g,'').trim().replaceAll(" ","_").toLowerCase()] = $("input[name='"+editorContentJson[i].split('}}')[0].replaceAll(/&nbsp;/g,'').trim().replaceAll(" ","_").toLowerCase()+"']").val() ? $("input[name='"+editorContentJson[i].split('}}')[0].replaceAll(/&nbsp;/g,'').trim().replaceAll(" ","_").toLowerCase()+"']").val() : "";
                        
                     }
                  }            
               }
               
               jsonItem["template_id"] = $('input[name=template_number]').val();
               jsonItem["data"] = jsonItemData;
            }
         }
         $(".jsonDataSample").html('');
         $(".jsonDataSample").html("<pre>"+JSON.stringify(jsonItem,null,4)+"</pre>");
      }
   });
   initAccordion();
});
function updateSampleTable() {
   var loopPresent = false;
   $("#generateSample").find('table tr').remove();
   editorContent = tinymce.get("tinymceEditor").getContent();
   // console.log(editorContent);
   var tinymceContent = editorContent.split('{{');
   // console.log("tinymceContent");
   // console.log(tinymceContent);
   for(var i = 0; i < tinymceContent.length; i++){
      // console.log(tinymceContent[i]);
      if(tinymceContent[i].indexOf("loop-starts-here") != -1){
         loopPresent = true;
      }
      if(tinymceContent[i].indexOf("}}") != -1){
         tinymceContent[i].split('}}')[0];
         $("#generateSample").find('table').append("<tr><td><label>"+tinymceContent[i].split('}}')[0].trim().replace("_"," ")+"</label></td><td><input type='text' class='field-input' name='"+tinymceContent[i].split('}}')[0].replaceAll(/&nbsp;/g,'').trim().replaceAll(" ","_").toLowerCase()+"' placeholder='"+tinymceContent[i].split('}}')[0].trim().replace("_"," ")+"'></td></tr>")
      }
   }
   
   if(loopPresent){
      $('body').append("<div style='display:none' id='editroDiv'>"+editorContent+"</div>");
      $("#editroDiv").find(".repeaterTablePreview").prevAll().remove();
      $("#editroDiv").find(".repeaterTablePreview").nextAll().remove();
      var repeaterField = [];
      var repeaterFieldTH = [];
      var tinyWithRepeaterTR = editorContent.split("<tr>");
      // console.log('');
      // console.log('tinyWithRepeaterTR');
      // console.log(tinyWithRepeaterTR);
      var repeaterRowContent = '';
      
      // for(var i = 0; i < tinyWithRepeaterTR.length; i++){
      //    if(tinyWithRepeaterTR[i].indexOf("<td>") != -1){
      //       repeaterField = tinyWithRepeaterTR[i].split("</tr>")[0].split("}}");
      //    }
      //    if(tinyWithRepeaterTR[i].indexOf("<th>") != -1){
      //       repeaterFieldTH = tinyWithRepeaterTR[i].split("</tr>")[0];
      //    }
      // }
      
      $("#editroDiv").find(".repeaterTablePreview").find('thead tr').html()
      repeaterFieldTH.push($("#editroDiv").find(".repeaterTablePreview").find('thead tr').html());
      $("#editroDiv").find(".repeaterTablePreview").find('td').each(function(){
         // console.log($(this).html().replaceAll("}",''));
         repeaterField.push($(this).html().replaceAll("}",''));
      });
      for(var j = 0; j<repeaterField.length;j++){
         
         // console.log('');
         // console.log("initial in td");
         // console.log(repeaterField[j]);
         if(repeaterField[j].indexOf("<td>") != -1){
            repeaterField[j] = repeaterField[j].replace("<td>",'');
         }
         if(repeaterField[j].indexOf("</td>") != -1){
            repeaterField[j] = repeaterField[j].replace("</td>",'');
         }
         // console.log(repeaterField[j].indexOf("{{"));
         if(repeaterField[j].indexOf("{{") >= 0){
            if(repeaterField[j].match(/{{/g).length > 1) {
               // console.log("Multiple Input");
               // console.log(repeaterField[j].indexOf("{{"));
               // console.log(repeaterField[j].match(/{{/g).length);
               // console.log(repeaterField[j].split(" x "));
               repeaterField[j] = repeaterField[j].split(" x ");
               // console.log(repeaterField[j]);
               var multiInp = [];
               for (var m = 0; m < repeaterField[j].length;m++) {
                  // console.log(repeaterField[j][m].split("{{")[1]);
                  repeaterField[j][m] = repeaterField[j][m].split("{{")[1];
                  repeaterField[j][m] = repeaterField[j][m].split(" ")[1];
                  $("input[name='"+repeaterField[j][m]+"']").closest("tr").prev('tr').addClass("repeaterRowPrev")
                  $("input[name='"+repeaterField[j][m]+"']").closest("tr").remove()
               }
            }
            else {
               // console.log("Single Input");
               repeaterField[j] = repeaterField[j].split("{{")[1].split(" ")[1];
               repeaterField[j] = $.trim(repeaterField[j]);
               $("input[name='"+repeaterField[j]+"']").closest("tr").prev('tr').addClass("repeaterRowPrev")
               $("input[name='"+repeaterField[j]+"']").closest("tr").remove()
            }
         } else {
            repeaterField[j] = repeaterField[j]
         }
         
         // console.log(repeaterField[j]);
      }
      repeaterRowContent += '<tr class="RepeaterRowSample">'+
      '<td colspan="2"><div class="table-responsive"><table class="">'+
      '<thead>'+
      '<tr>'+repeaterFieldTH+'<th>Action</th></tr>'+
      '</thead>'+
      '<tbody>'+
      '<tr class="variableRow">';
      for(var j = 0; j<repeaterField.length;j++){
         if(repeaterField[j]){
            // console.log($.isArray(repeaterField[j]));
            if(!$.isArray(repeaterField[j])){
               // console.log("single input field");
               // console.log(repeaterField[j]);
               repeaterRowContent += '<td class="variableCell">'+
               '<input type="text" class="field-input" placeholder="'+repeaterField[j].split("line_item_")[1]+'" name="'+repeaterField[j]+'" data-originalVariable="'+repeaterField[j]+'">'+
               '</td>';
            } else {
               // console.log("multiple input field");
               repeaterRowContent += '<td class="variableCell"><div class="multiple">';
               for(var mm = 0;mm<repeaterField[j].length;mm++){
                  // console.log('*****************************************');
                  if(mm == repeaterField[j].length - 1) {
                     repeaterRowContent += '<input type="text" class="field-input" placeholder="'+repeaterField[j][mm].split("line_item_")[1]+'" name="'+repeaterField[j][mm]+'" data-originalVariable="'+repeaterField[j][mm]+'">';   
                  } else {
                     repeaterRowContent += '<input type="text" class="field-input" placeholder="'+repeaterField[j][mm].split("line_item_")[1]+'" name="'+repeaterField[j][mm]+'" data-originalVariable="'+repeaterField[j][mm]+'"> x ';
                  }
               }
               repeaterRowContent += '</div></td>';
            }
         }
      }
      repeaterRowContent +='<td>&nbsp;</td></tr>'+
      '<tr>'+
      '<td colspan="'+(repeaterField.length + 1)+'">'+
      '<button class="btn btn-primary" id="addNewRow" type="button"><i class="fas fa-plus-circle"></i></button>'+
      '</td>'+
      '</tr>'+
      '</tbody>'+
      '</table></div>'+
      '</td>'+
      '</tr>';
      if($(".repeaterRowPrev").length){
         $(repeaterRowContent).insertAfter($(".repeaterRowPrev"));
      } else {
         $("#editorFormFields").prepend(repeaterRowContent);
      }
      $("#editroDiv").remove();
   }
}
function generatePreviewInModal() {
   jsonFromPreview = true;
   editorContentToReplace = editorContent;
   jsonItem["data"] = '';
   jsonItemData = {};
   editorContentToReplace = editorContentToReplace.replaceAll("{{","");
   editorContentToReplace = editorContentToReplace.replaceAll("}}","");
   var repeaterItemsArray = [];
   if($("#editorFormFields").find(" > tr").length){
      $("#editorFormFields").find(" > tr").each(function(value,key){
         if($(this).hasClass("RepeaterRowSample") == true){
            $("tr.variableRow").each(function(){
               var variableRow = $(this);
               var repeaterItemsJsonData = {};
               variableRow.find(".variableCell").each(function(){
                  $(this).find("input").each(function(){
                     if($(this).val()){
                        repeaterItemsJsonData[$(this).attr('name').replace(".","_")] = $(this).val();
                     } else {
                        repeaterItemsJsonData[$(this).attr('name').replace(".","_")] = '';
                     }
                  });
               });
               repeaterItemsArray.push(repeaterItemsJsonData);
            });
            jsonItemData["items"] = repeaterItemsArray;
         } else {
            if($(this).find("input").val()){
               editorContentToReplace = editorContentToReplace.replace($(this).find("input").attr("name"),$(this).find("input").val());
               jsonItemData[$(this).find("input").attr("name").replace(".","_")] = $(this).find("input").val();
            } else {
               editorContentToReplace = editorContentToReplace.replace($(this).find("input").attr("name"),"{{"+$(this).find("input").attr("name")+"}}");
               jsonItemData[$(this).find("input").attr("name").replace(".","_")] = '';         
            }         
         }
      });
   }
   jsonItem["template_id"] = $('input[name=template_number]').val();
   jsonItem["data"] = jsonItemData;
   $(".jsonDataSample").html('');
   $(".jsonDataSample").html("<pre>"+JSON.stringify(jsonItem,null,4)+"</pre>");
   $(".editorContent-full").html('');
   $(".editorContent-full").append(editorContentToReplace);
   
   
   if($(".repeaterTablePreview").length) {
      $(".loop-starts-here").remove();
      $(".repeaterTablePreview tbody tr").remove();
      $("tr.variableRow").each(function(){
         var variableRow = $(this);
         var repeaterPreviewRow = '<tr>';
         variableRow.find(".variableCell").each(function(){
            var multiValues = '';
            $(this).find("input").each(function(index){
               if($(this).val()){
                  if(index > 0) {
                     multiValues += " x "+$(this).val();
                  } else {
                     multiValues += $(this).val();
                  }
               } else {
                  if(index > 0) {
                     multiValues += " x {{"+$(this).attr('data-originalVariable')+"}}";
                  } else {
                     multiValues += "{{"+$(this).attr('data-originalVariable')+"}}";
                  }
               }
            });
            repeaterPreviewRow +=  "<td>"+multiValues+"</td>";
         });
         repeaterPreviewRow += "</tr>";
         $(".repeaterTablePreview tbody").append(repeaterPreviewRow);
      });
   }
}

function initAccordion() {
   $(".accordion-header").removeClass("opened");
   $(".accordion-body").slideUp();
   $(".accordion-item:first-child").find(".accordion-header").addClass("opened");
   $(".accordion-item:first-child").find(".accordion-body").slideDown();
   
   $(".accordion-header").each(function(){
      $(this).unbind();
      $(this).click(function(e){
         e.preventDefault();
         if($(this).hasClass("opened")){
            $(this).removeClass("opened");
         } else{
            $(this).addClass("opened");
         }
         $(this).parent(".accordion-item").find(".accordion-body").slideToggle();
         $(this).parent(".accordion-item").prevAll(".accordion-item").find(".accordion-body").slideUp();
         $(this).parent(".accordion-item").prevAll(".accordion-item").find(".accordion-header").removeClass("opened");;
         $(this).parent(".accordion-item").nextAll(".accordion-item").find(".accordion-body").slideUp();
         $(this).parent(".accordion-item").nextAll(".accordion-item").find(".accordion-header").removeClass("opened");
      });
   });
}